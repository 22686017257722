exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "\n", ""]);

// Exports
exports.locals = {
	"blue": "#0077ff",
	"orange": "#ff6100",
	"black": "#171717",
	"darkGrey": "#9a9a9a",
	"grey": "#c3c3c3",
	"lightGrey": "#e4e4e4",
	"extraLightGrey": "#f3f3f3",
	"white": "#ffffff",
	"legend_point_1": "#004744",
	"legend_point_2": "#008f88",
	"legend_point_3": "#00d6cb",
	"legend_point_4": "#1ffff4",
	"legend_point_5": "#66fff7"
};