export const GET_EXP_SUMMARY_DATA = "GET_EXP_SUMMARY_DATA";
export const SET_EXP_SUMMARY_DATA_LOADING = "SET_EXP_SUMMARY_DATA_LOADING";
export const EXP_SUMMARY_DATA_ERROR = "EXP_SUMMARY_DATA_ERROR";

//get exp demandwise data
export const GET_EXP_DEMANDWISE_DATA = "GET_EXP_DEMANDWISE_DATA";
export const SET_DATA_LOADING_EXP_DEMANDWISE =
  "SET_DATA_LOADING_EXP_DEMANDWISE";
export const EXP_DEMANDWISE_DATA_ERROR = "EXP_DEMANDWISE_DATA_ERROR";

//get exp demandwise filters
export const GET_EXP_DEMANDWISE_FILTERS_DATA =
  "GET_EXP_DEMANDWISE_FILTERS_DATA";
export const UPDATE_EXP_DEMANDWISE_FILTERS_DATA =
  "UPDATE_EXP_DEMANDWISE_FILTERS_DATA";
export const EXP_DEMANDWISE_FILTERS_DATA_ERROR =
  "EXP_DEMANDWISE_FILTERS_DATA_ERROR";
export const SET_DATA_LOADING_EXP_DEMANDWISE_FILTERS =
  "SET_DATA_LOADING_EXP_DEMANDWISE_FILTERS";

//get exp districtwise data
export const DISTRICTWISE_SWITCH_ACTIVE_VIZ_IDX =
  "DISTRICTWISE_SWITCH_ACTIVE_VIZ_IDX";
export const GET_EXP_DISTRICTWISE_DATA = "GET_EXP_DISTRICTWISE_DATA";
export const HYDRATE_EXP_DISTRICTWISE_DATA_FROM_INITDATA =
  "HYDRATE_EXP_DISTRICTWISE_DATA_FROM_INITDATA";
export const SET_DATA_LOADING_EXP_DISTRICTWISE =
  "SET_DATA_LOADING_EXP_DISTRICTWISE";
export const EXP_DISTRICTWISE_DATA_ERROR = "EXP_DISTRICTWISE_DATA_ERROR";
export const RESET_ACTIVE_FILTERS_AND_DATE_RANGE_DISTRICTWISE =
  "RESET_ACTIVE_FILTERS_AND_DATE_RANGE_DISTRICTWISE";

//get exp districtwise filters
export const GET_EXP_DISTRICTWISE_FILTERS_DATA =
  "GET_EXP_DISTRICTWISE_FILTERS_DATA";
export const EXP_DISTRICTWISE_FILTERS_DATA_ERROR =
  "EXP_DISTRICTWISE_FILTERS_DATA_ERROR";
export const SET_DATA_LOADING_EXP_DISTRICTWISE_FILTERS =
  "SET_DATA_LOADING_EXP_DISTRICTWISE_FILTERS";
export const UPDATE_EXP_DISTRICTWISE_FILTERS_DATA =
  "UPDATE_EXP_DISTRICTWISE_FILTERS_DATA";

//get receipts districtwise data
export const RECEIPTS_DISTRICTWISE_SWITCH_ACTIVE_VIZ_IDX =
  "RECEIPTS_DISTRICTWISE_SWITCH_ACTIVE_VIZ_IDX";
export const GET_RECEIPTS_DISTRICTWISE_DATA = "GET_RECEIPTS_DISTRICTWISE_DATA";
export const HYDRATE_RECEIPTS_DISTRICTWISE_DATA_FROM_INITDATA =
  "HYDRATE_RECEIPTS_DISTRICTWISE_DATA_FROM_INITDATA";
export const SET_DATA_LOADING_RECEIPTS_DISTRICTWISE =
  "SET_DATA_LOADING_RECEIPTS_DISTRICTWISE";
export const RECEIPTS_DISTRICTWISE_DATA_ERROR =
  "RECEIPTS_DISTRICTWISE_DATA_ERROR";
export const RESET_ACTIVE_FILTERS_AND_DATE_RANGE_RECEIPTS_DISTRICTWISE =
  "RESET_ACTIVE_FILTERS_AND_DATE_RANGE_RECEIPTS_DISTRICTWISE";

//get receipts districtwise filters
export const GET_RECEIPTS_DISTRICTWISE_FILTERS_DATA =
  "GET_RECEIPTS_DISTRICTWISE_FILTERS_DATA";
export const RECEIPTS_DISTRICTWISE_FILTERS_DATA_ERROR =
  "RECEIPTS_DISTRICTWISE_FILTERS_DATA_ERROR";
export const SET_DATA_LOADING_RECEIPTS_DISTRICTWISE_FILTERS =
  "SET_DATA_LOADING_RECEIPTS_DISTRICTWISE_FILTERS";
export const UPDATE_RECEIPTS_DISTRICTWISE_FILTERS_DATA =
  "UPDATE_RECEIPTS_DISTRICTWISE_FILTERS_DATA";

//get exp schemes data
export const GET_EXP_SCHEMES_DATA = "GET_EXP_SCHEMES_DATA";
export const SET_DATA_LOADING_EXP_SCHEMES = "SET_DATA_LOADING_EXP_SCHEMES";
export const RESET_ACTIVE_FILTERS_AND_DATE_RANGE_SCHEMES =
  "RESET_ACTIVE_FILTERS_AND_DATE_RANGE_SCHEMES";
export const EXP_SCHEMES_DATA_ERROR = "EXP_SCHEMES_DATA_ERROR";
export const HYDRATE_SCHEMES_DATA_FROM_INITDATA =
  "HYDRATE_SCHEMES_DATA_FROM_INITDATA";

//get exp schemes filters
export const GET_EXP_SCHEMES_FILTERS_DATA = "GET_EXP_SCHEMES_FILTERS_DATA";
export const EXP_SCHEMES_FILTERS_DATA_ERROR = "EXP_SCHEMES_FILTERS_DATA_ERROR";
export const SET_DATA_LOADING_EXP_SCHEMES_FILTERS =
  "SET_DATA_LOADING_EXP_SCHEMES_FILTERS";
export const UPDATE_EXP_SCHEMES_FILTERS_DATA =
  "UPDATE_EXP_SCHEMES_FILTERS_DATA";

//get receipts data
export const GET_RECEIPTS_DATA = "GET_RECEIPTS_DATA";
export const SET_DATA_LOADING_RECEIPTS = "SET_DATA_LOADING_RECEIPTS";
export const RECEIPTS_DATA_ERROR = "RECEIPTS_DATA_ERROR";

//get receipts filters
export const GET_RECEIPTS_FILTERS_DATA = "GET_RECEIPTS_FILTERS_DATA";
export const UPDATE_RECEIPTS_FILTERS_DATA = "UPDATE_RECEIPTS_FILTERS_DATA";
export const RECEIPTS_FILTERS_DATA_ERROR = "RECEIPTS_FILTERS_DATA_ERROR";
export const SET_LOADING_RECEIPTS_FILTERS = "SET_LOADING_RECEIPTS_FILTERS";

// get exp covid data
export const GET_EXP_COVID_DATA = "GET_EXP_COVID_DATA";
export const SET_DATA_LOADING_EXP_COVID = "SET_DATA_LOADING_EXP_COVID";
export const EXP_COVID_DATA_ERROR = "EXP_COVID_DATA_ERROR";

// get procurements data
export const GET_PROCUREMENTS_DATA = "GET_PROCUREMENTS_DATA";
export const SET_PROCUREMENTS_DATA_LOADING = "SET_PROCUREMENTS_DATA_LOADING";
export const PROCUREMENTS_DATA_ERROR = "PROCUREMENTS_DATA_ERROR";
export const UPDATE_DATA_WITH_FILTERS = "UPDATE_DATA_WITH_FILTERS";
export const UPDATE_DATERANGE = "UPDATE_DATERANGE";

export const GET_PROCUREMENTS_DATA_API = "GET_PROCUREMENTS_DATA_API";
export const SET_PROCUREMENTS_DATA_LOADING_API =
  "SET_PROCUREMENTS_DATA_LOADING_API";
export const EXP_PROCUREMENTS_DATA_ERROR_API =
  "EXP_PROCUREMENTS_DATA_ERROR_API";

export const GET_PROCUREMENTS_DATA_AWARDS_API =
  "GET_PROCUREMENTS_DATA_AWARDS_API";
export const SET_PROCUREMENTS_DATA_AWARDS_LOADING_API =
  "SET_PROCUREMENTS_DATA_AWARDS_LOADING_API";
export const EXP_PROCUREMENTS_DATA_AWARDS_ERROR_API =
  "EXP_PROCUREMENTS_DATA_AWARDS_ERROR_API";
