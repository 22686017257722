import React from "react"
import FDidYouKnowCard from "../../molecules/FDidYouKnowCard"



const FDidYouKnowSection = () => {

    return(
        <div className="">
            <h1>Did You Know...</h1>
            <FDidYouKnowCard />
        </div>
    )
}

export default FDidYouKnowSection