export const KPIDistrictMapping = {
	"2017-2018": {
		values: {
			"Average number of bids received per tender": {
				values: {
					HAMIRPUR: 4.17,
					KULLU: 4.01,
					SHIMLA: 2.82,
					SIRMAUR: 3.85,
					CHAMBA: 3.33,
					UNA: 4.17,
					BILASPUR: 3.44,
					KANGRA: 4.33,
					KINNAUR: "null",
					MANDI: 3.84,
					SOLAN: 7.95,
					"LAHAUL AND SPITI": "null",
				},
				index: {
					HAMIRPUR: 0.26,
					KULLU: 0.23,
					SHIMLA: 0.0,
					SIRMAUR: 0.2,
					CHAMBA: 0.1,
					UNA: 0.26,
					BILASPUR: 0.12,
					KANGRA: 0.29,
					KINNAUR: "null",
					MANDI: 0.2,
					SOLAN: 1.0,
					"LAHAUL AND SPITI": "null",
				},
				type: "kpi",
			},
			"Average days taken for bid evaluation": {
				values: {
					HAMIRPUR: 658.74,
					KULLU: 533.93,
					SHIMLA: 360.99,
					SIRMAUR: 419.34,
					CHAMBA: 537.02,
					UNA: 597.55,
					BILASPUR: 301.88,
					KANGRA: 510.39,
					KINNAUR: "null",
					MANDI: 406.85,
					SOLAN: 267.68,
					"LAHAUL AND SPITI": "null",
				},
				index: {
					HAMIRPUR: 1.0,
					KULLU: 0.68,
					SHIMLA: 0.24,
					SIRMAUR: 0.39,
					CHAMBA: 0.69,
					UNA: 0.84,
					BILASPUR: 0.09,
					KANGRA: 0.62,
					KINNAUR: "null",
					MANDI: 0.36,
					SOLAN: 0.0,
					"LAHAUL AND SPITI": "null",
				},
				type: "kpi",
			},
			"Spending Per Capita": {
				values: {
					HAMIRPUR: 1329.32,
					KULLU: 24.82,
					SHIMLA: 112.19,
					SIRMAUR: 95.33,
					CHAMBA: 121.98,
					UNA: 12.58,
					BILASPUR: 19.71,
					KANGRA: 24.5,
					KINNAUR: 8.83,
					MANDI: 21.51,
					SOLAN: 14.02,
					"LAHAUL AND SPITI": "null",
				},
				index: {
					HAMIRPUR: 1.0,
					KULLU: 0.01,
					SHIMLA: 0.08,
					SIRMAUR: 0.07,
					CHAMBA: 0.09,
					UNA: 0.0,
					BILASPUR: 0.01,
					KANGRA: 0.01,
					KINNAUR: 0.0,
					MANDI: 0.01,
					SOLAN: 0.0,
					"LAHAUL AND SPITI": "null",
				},
				type: "kpi",
			},
			"Average difference between awarded and tender value": {
				values: {
					HAMIRPUR: -6061965.62,
					KULLU: 1427952.73,
					SHIMLA: 986718.05,
					SIRMAUR: 702438.73,
					CHAMBA: 1821382.1,
					UNA: 409134.08,
					BILASPUR: 912553.52,
					KANGRA: 3076724.14,
					KINNAUR: "null",
					MANDI: 227877.32,
					SOLAN: -9537.77,
					"LAHAUL AND SPITI": "null",
				},
				index: {
					HAMIRPUR: 0.0,
					KULLU: 0.82,
					SHIMLA: 0.77,
					SIRMAUR: 0.74,
					CHAMBA: 0.86,
					UNA: 0.71,
					BILASPUR: 0.76,
					KANGRA: 1.0,
					KINNAUR: "null",
					MANDI: 0.69,
					SOLAN: 0.66,
					"LAHAUL AND SPITI": "null",
				},
				type: "kpi",
			},
			"Number of tenders concluded after validity days": {
				values: {
					HAMIRPUR: 5.5,
					KULLU: 5.0,
					SHIMLA: 5.56,
					SIRMAUR: 3.6,
					CHAMBA: 3.0,
					UNA: 3.25,
					BILASPUR: 2.17,
					KANGRA: 4.78,
					KINNAUR: "null",
					MANDI: 5.13,
					SOLAN: 3.1,
					"LAHAUL AND SPITI": "null",
				},
				index: {
					HAMIRPUR: 0.98,
					KULLU: 0.84,
					SHIMLA: 1.0,
					SIRMAUR: 0.42,
					CHAMBA: 0.25,
					UNA: 0.32,
					BILASPUR: 0.0,
					KANGRA: 0.77,
					KINNAUR: "null",
					MANDI: 0.87,
					SOLAN: 0.28,
					"LAHAUL AND SPITI": "null",
				},
				type: "kpi",
			},
			"Average days allowed for bid submission": {
				values: {
					HAMIRPUR: 12.78,
					KULLU: 18.21,
					SHIMLA: 23.2,
					SIRMAUR: 16.64,
					CHAMBA: 18.86,
					UNA: 15.03,
					BILASPUR: 21.08,
					KANGRA: 18.94,
					KINNAUR: "null",
					MANDI: 16.42,
					SOLAN: 19.34,
					"LAHAUL AND SPITI": "null",
				},
				index: {
					HAMIRPUR: 0.0,
					KULLU: 0.52,
					SHIMLA: 1.0,
					SIRMAUR: 0.37,
					CHAMBA: 0.58,
					UNA: 0.22,
					BILASPUR: 0.8,
					KANGRA: 0.59,
					KINNAUR: "null",
					MANDI: 0.35,
					SOLAN: 0.63,
					"LAHAUL AND SPITI": "null",
				},
				type: "kpi",
			},
		},
		index: {},
		type: "fiscal_year",
	},
	"2019-2020": {
		values: {
			"Average number of bids received per tender": {
				values: {
					HAMIRPUR: 6.0,
					KULLU: 4.0,
					SHIMLA: 4.31,
					SIRMAUR: 4.83,
					CHAMBA: 3.0,
					UNA: 3.53,
					BILASPUR: 4.72,
					KANGRA: 3.95,
					KINNAUR: "null",
					MANDI: 4.98,
					SOLAN: 4.32,
					"LAHAUL AND SPITI": "null",
				},
				index: {
					HAMIRPUR: 1.0,
					KULLU: 0.33,
					SHIMLA: 0.44,
					SIRMAUR: 0.61,
					CHAMBA: 0.0,
					UNA: 0.18,
					BILASPUR: 0.57,
					KANGRA: 0.32,
					KINNAUR: "null",
					MANDI: 0.66,
					SOLAN: 0.44,
					"LAHAUL AND SPITI": "null",
				},
				type: "kpi",
			},
			"Average days taken for bid evaluation": {
				values: {
					HAMIRPUR: 76.17,
					KULLU: 87.0,
					SHIMLA: 101.22,
					SIRMAUR: 76.13,
					CHAMBA: 132.0,
					UNA: 96.21,
					BILASPUR: 94.88,
					KANGRA: 81.22,
					KINNAUR: "null",
					MANDI: 98.57,
					SOLAN: 74.25,
					"LAHAUL AND SPITI": "null",
				},
				index: {
					HAMIRPUR: 0.03,
					KULLU: 0.22,
					SHIMLA: 0.47,
					SIRMAUR: 0.03,
					CHAMBA: 1.0,
					UNA: 0.38,
					BILASPUR: 0.36,
					KANGRA: 0.12,
					KINNAUR: "null",
					MANDI: 0.42,
					SOLAN: 0.0,
					"LAHAUL AND SPITI": "null",
				},
				type: "kpi",
			},
			"Spending Per Capita": {
				values: {
					HAMIRPUR: 93.43,
					KULLU: 49.15,
					SHIMLA: 344.7,
					SIRMAUR: 5.44,
					CHAMBA: 5.21,
					UNA: 1.27,
					BILASPUR: 29.39,
					KANGRA: 40.31,
					KINNAUR: 48.02,
					MANDI: 45.84,
					SOLAN: 2.1,
					"LAHAUL AND SPITI": "null",
				},
				index: {
					HAMIRPUR: 0.27,
					KULLU: 0.14,
					SHIMLA: 1.0,
					SIRMAUR: 0.01,
					CHAMBA: 0.01,
					UNA: 0.0,
					BILASPUR: 0.08,
					KANGRA: 0.11,
					KINNAUR: 0.14,
					MANDI: 0.13,
					SOLAN: 0.0,
					"LAHAUL AND SPITI": "null",
				},
				type: "kpi",
			},
			"Average difference between awarded and tender value": {
				values: {
					HAMIRPUR: 201300.0,
					KULLU: -29000.0,
					SHIMLA: 315371.93,
					SIRMAUR: 59587.78,
					CHAMBA: 37500.0,
					UNA: 5915728.74,
					BILASPUR: -451225.92,
					KANGRA: -237250.68,
					KINNAUR: "null",
					MANDI: 512741.15,
					SOLAN: 2393776.17,
					"LAHAUL AND SPITI": "null",
				},
				index: {
					HAMIRPUR: 0.1,
					KULLU: 0.07,
					SHIMLA: 0.12,
					SIRMAUR: 0.08,
					CHAMBA: 0.08,
					UNA: 1.0,
					BILASPUR: 0.0,
					KANGRA: 0.03,
					KINNAUR: "null",
					MANDI: 0.15,
					SOLAN: 0.45,
					"LAHAUL AND SPITI": "null",
				},
				type: "kpi",
			},
			"Number of tenders concluded after validity days": {
				values: {
					HAMIRPUR: 3.0,
					KULLU: 1.0,
					SHIMLA: 2.5,
					SIRMAUR: 2.67,
					CHAMBA: 1.0,
					UNA: 3.43,
					BILASPUR: 4.75,
					KANGRA: 4.77,
					KINNAUR: "null",
					MANDI: 3.54,
					SOLAN: 2.5,
					"LAHAUL AND SPITI": "null",
				},
				index: {
					HAMIRPUR: 0.53,
					KULLU: 0.0,
					SHIMLA: 0.4,
					SIRMAUR: 0.44,
					CHAMBA: 0.0,
					UNA: 0.64,
					BILASPUR: 0.99,
					KANGRA: 1.0,
					KINNAUR: "null",
					MANDI: 0.67,
					SOLAN: 0.4,
					"LAHAUL AND SPITI": "null",
				},
				type: "kpi",
			},
			"Average days allowed for bid submission": {
				values: {
					HAMIRPUR: 15.33,
					KULLU: 22.0,
					SHIMLA: 14.79,
					SIRMAUR: 18.23,
					CHAMBA: 13.0,
					UNA: 12.33,
					BILASPUR: 17.92,
					KANGRA: 12.83,
					KINNAUR: "null",
					MANDI: 19.44,
					SOLAN: 15.96,
					"LAHAUL AND SPITI": "null",
				},
				index: {
					HAMIRPUR: 0.31,
					KULLU: 1.0,
					SHIMLA: 0.25,
					SIRMAUR: 0.61,
					CHAMBA: 0.07,
					UNA: 0.0,
					BILASPUR: 0.58,
					KANGRA: 0.05,
					KINNAUR: "null",
					MANDI: 0.74,
					SOLAN: 0.38,
					"LAHAUL AND SPITI": "null",
				},
				type: "kpi",
			},
		},
		index: {},
		type: "fiscal_year",
	},
	"2020-2021": {
		values: {
			"Average number of bids received per tender": {
				values: {
					HAMIRPUR: 4.5,
					KULLU: "null",
					SHIMLA: 4.33,
					SIRMAUR: 5.67,
					CHAMBA: 7.0,
					UNA: 7.5,
					BILASPUR: 3.75,
					KANGRA: 5.0,
					KINNAUR: "null",
					MANDI: 3.0,
					SOLAN: "null",
					"LAHAUL AND SPITI": "null",
				},
				index: {
					HAMIRPUR: 0.33,
					KULLU: "null",
					SHIMLA: 0.3,
					SIRMAUR: 0.59,
					CHAMBA: 0.89,
					UNA: 1.0,
					BILASPUR: 0.17,
					KANGRA: 0.44,
					KINNAUR: "null",
					MANDI: 0.0,
					SOLAN: "null",
					"LAHAUL AND SPITI": "null",
				},
				type: "kpi",
			},
			"Average days taken for bid evaluation": {
				values: {
					HAMIRPUR: 55.5,
					KULLU: "null",
					SHIMLA: 33.0,
					SIRMAUR: 37.33,
					CHAMBA: 39.0,
					UNA: 51.0,
					BILASPUR: 35.0,
					KANGRA: 39.5,
					KINNAUR: "null",
					MANDI: 36.0,
					SOLAN: "null",
					"LAHAUL AND SPITI": "null",
				},
				index: {
					HAMIRPUR: 1.0,
					KULLU: "null",
					SHIMLA: 0.0,
					SIRMAUR: 0.19,
					CHAMBA: 0.27,
					UNA: 0.8,
					BILASPUR: 0.09,
					KANGRA: 0.29,
					KINNAUR: "null",
					MANDI: 0.13,
					SOLAN: "null",
					"LAHAUL AND SPITI": "null",
				},
				type: "kpi",
			},
			"Spending Per Capita": {
				values: {
					HAMIRPUR: 0.82,
					KULLU: 0.55,
					SHIMLA: 1989.77,
					SIRMAUR: 0.6,
					CHAMBA: "null",
					UNA: 0.7,
					BILASPUR: 1.06,
					KANGRA: 0.66,
					KINNAUR: 2.48,
					MANDI: 4.62,
					SOLAN: 0.35,
					"LAHAUL AND SPITI": "null",
				},
				index: {
					HAMIRPUR: 0.0,
					KULLU: 0.0,
					SHIMLA: 1.0,
					SIRMAUR: 0.0,
					CHAMBA: "null",
					UNA: 0.0,
					BILASPUR: 0.0,
					KANGRA: 0.0,
					KINNAUR: 0.0,
					MANDI: 0.0,
					SOLAN: 0.0,
					"LAHAUL AND SPITI": "null",
				},
				type: "kpi",
			},
			"Average difference between awarded and tender value": {
				values: {
					HAMIRPUR: 0.0,
					KULLU: "null",
					SHIMLA: 2941609.06,
					SIRMAUR: 0.0,
					CHAMBA: 0.0,
					UNA: 19431.5,
					BILASPUR: 932046.0,
					KANGRA: -469925.84,
					KINNAUR: "null",
					MANDI: -18119.0,
					SOLAN: "null",
					"LAHAUL AND SPITI": "null",
				},
				index: {
					HAMIRPUR: 0.14,
					KULLU: "null",
					SHIMLA: 1.0,
					SIRMAUR: 0.14,
					CHAMBA: 0.14,
					UNA: 0.14,
					BILASPUR: 0.41,
					KANGRA: 0.0,
					KINNAUR: "null",
					MANDI: 0.13,
					SOLAN: "null",
					"LAHAUL AND SPITI": "null",
				},
				type: "kpi",
			},
			"Number of tenders concluded after validity days": {
				values: {
					HAMIRPUR: 2.0,
					KULLU: "null",
					SHIMLA: 1.33,
					SIRMAUR: 1.0,
					CHAMBA: 1.0,
					UNA: 1.0,
					BILASPUR: 1.0,
					KANGRA: 8.0,
					KINNAUR: "null",
					MANDI: 1.0,
					SOLAN: "null",
					"LAHAUL AND SPITI": "null",
				},
				index: {
					HAMIRPUR: 0.14,
					KULLU: "null",
					SHIMLA: 0.05,
					SIRMAUR: 0.0,
					CHAMBA: 0.0,
					UNA: 0.0,
					BILASPUR: 0.0,
					KANGRA: 1.0,
					KINNAUR: "null",
					MANDI: 0.0,
					SOLAN: "null",
					"LAHAUL AND SPITI": "null",
				},
				type: "kpi",
			},
			"Average days allowed for bid submission": {
				values: {
					HAMIRPUR: 13.0,
					KULLU: "null",
					SHIMLA: 11.39,
					SIRMAUR: 16.67,
					CHAMBA: 54.0,
					UNA: 14.0,
					BILASPUR: 21.5,
					KANGRA: 21.25,
					KINNAUR: "null",
					MANDI: 12.0,
					SOLAN: "null",
					"LAHAUL AND SPITI": "null",
				},
				index: {
					HAMIRPUR: 0.04,
					KULLU: "null",
					SHIMLA: 0.0,
					SIRMAUR: 0.12,
					CHAMBA: 1.0,
					UNA: 0.06,
					BILASPUR: 0.24,
					KANGRA: 0.23,
					KINNAUR: "null",
					MANDI: 0.01,
					SOLAN: "null",
					"LAHAUL AND SPITI": "null",
				},
				type: "kpi",
			},
		},
		index: {},
		type: "fiscal_year",
	},
	"2018-2019": {
		values: {
			"Average number of bids received per tender": {
				values: {
					HAMIRPUR: 4.17,
					KULLU: 5.49,
					SHIMLA: 5.14,
					SIRMAUR: 3.82,
					CHAMBA: 4.19,
					UNA: 4.64,
					BILASPUR: 3.97,
					KANGRA: 4.21,
					KINNAUR: 3.5,
					MANDI: 3.83,
					SOLAN: 4.97,
					"LAHAUL AND SPITI": "null",
				},
				index: {
					HAMIRPUR: 0.34,
					KULLU: 1.0,
					SHIMLA: 0.83,
					SIRMAUR: 0.16,
					CHAMBA: 0.35,
					UNA: 0.57,
					BILASPUR: 0.23,
					KANGRA: 0.36,
					KINNAUR: 0.0,
					MANDI: 0.17,
					SOLAN: 0.74,
					"LAHAUL AND SPITI": "null",
				},
				type: "kpi",
			},
			"Average days taken for bid evaluation": {
				values: {
					HAMIRPUR: 144.26,
					KULLU: 152.33,
					SHIMLA: 160.19,
					SIRMAUR: 120.73,
					CHAMBA: 222.75,
					UNA: 154.38,
					BILASPUR: 156.53,
					KANGRA: 81.35,
					KINNAUR: 157.5,
					MANDI: 180.08,
					SOLAN: 161.0,
					"LAHAUL AND SPITI": "null",
				},
				index: {
					HAMIRPUR: 0.44,
					KULLU: 0.5,
					SHIMLA: 0.56,
					SIRMAUR: 0.28,
					CHAMBA: 1.0,
					UNA: 0.52,
					BILASPUR: 0.53,
					KANGRA: 0.0,
					KINNAUR: 0.54,
					MANDI: 0.7,
					SOLAN: 0.56,
					"LAHAUL AND SPITI": "null",
				},
				type: "kpi",
			},
			"Spending Per Capita": {
				values: {
					HAMIRPUR: 195.66,
					KULLU: 48.3,
					SHIMLA: 128.59,
					SIRMAUR: 145.54,
					CHAMBA: 144.13,
					UNA: 13.94,
					BILASPUR: 26.66,
					KANGRA: 32.36,
					KINNAUR: 28.97,
					MANDI: 44.56,
					SOLAN: 29.39,
					"LAHAUL AND SPITI": "null",
				},
				index: {
					HAMIRPUR: 1.0,
					KULLU: 0.19,
					SHIMLA: 0.63,
					SIRMAUR: 0.72,
					CHAMBA: 0.72,
					UNA: 0.0,
					BILASPUR: 0.07,
					KANGRA: 0.1,
					KINNAUR: 0.08,
					MANDI: 0.17,
					SOLAN: 0.09,
					"LAHAUL AND SPITI": "null",
				},
				type: "kpi",
			},
			"Average difference between awarded and tender value": {
				values: {
					HAMIRPUR: 33741.15,
					KULLU: -51347.88,
					SHIMLA: 911097.43,
					SIRMAUR: 27213.19,
					CHAMBA: 590196.81,
					UNA: 9696408.7,
					BILASPUR: -1246910.23,
					KANGRA: 918949.41,
					KINNAUR: 1920022.5,
					MANDI: -1895439.13,
					SOLAN: -74519.01,
					"LAHAUL AND SPITI": "null",
				},
				index: {
					HAMIRPUR: 0.17,
					KULLU: 0.16,
					SHIMLA: 0.24,
					SIRMAUR: 0.17,
					CHAMBA: 0.21,
					UNA: 1.0,
					BILASPUR: 0.06,
					KANGRA: 0.24,
					KINNAUR: 0.33,
					MANDI: 0.0,
					SOLAN: 0.16,
					"LAHAUL AND SPITI": "null",
				},
				type: "kpi",
			},
			"Number of tenders concluded after validity days": {
				values: {
					HAMIRPUR: 3.25,
					KULLU: 2.8,
					SHIMLA: 3.43,
					SIRMAUR: 3.13,
					CHAMBA: 3.5,
					UNA: 4.67,
					BILASPUR: 1.8,
					KANGRA: 7.91,
					KINNAUR: 1.0,
					MANDI: 4.47,
					SOLAN: 3.75,
					"LAHAUL AND SPITI": "null",
				},
				index: {
					HAMIRPUR: 0.33,
					KULLU: 0.26,
					SHIMLA: 0.35,
					SIRMAUR: 0.31,
					CHAMBA: 0.36,
					UNA: 0.53,
					BILASPUR: 0.12,
					KANGRA: 1.0,
					KINNAUR: 0.0,
					MANDI: 0.5,
					SOLAN: 0.4,
					"LAHAUL AND SPITI": "null",
				},
				type: "kpi",
			},
			"Average days allowed for bid submission": {
				values: {
					HAMIRPUR: 10.94,
					KULLU: 18.05,
					SHIMLA: 15.83,
					SIRMAUR: 13.57,
					CHAMBA: 17.63,
					UNA: 15.26,
					BILASPUR: 13.82,
					KANGRA: 15.23,
					KINNAUR: 15.0,
					MANDI: 15.19,
					SOLAN: 16.61,
					"LAHAUL AND SPITI": "null",
				},
				index: {
					HAMIRPUR: 0.0,
					KULLU: 1.0,
					SHIMLA: 0.69,
					SIRMAUR: 0.37,
					CHAMBA: 0.94,
					UNA: 0.61,
					BILASPUR: 0.4,
					KANGRA: 0.6,
					KINNAUR: 0.57,
					MANDI: 0.6,
					SOLAN: 0.8,
					"LAHAUL AND SPITI": "null",
				},
				type: "kpi",
			},
		},
		index: {},
		type: "fiscal_year",
	},
};
